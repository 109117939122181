<template>
  <b-container class="pt-4">
    <b-row class="mb-4">
      <b-col class="d-flex justify-content-between">
        <curva-title :title="$t('terms.title')"/>
      </b-col>
    </b-row>
    <b-row><b-col>
      <b-card class="details-container iq-style4 mb-5">
        <b-row class="d-flex justify-content-center text-initial">
          <b-col v-for="(item, key) in structure" :key="key"
              lg="10" sm="12" class="mb-4">
              <h3 class="text-warning" v-if="item.length === 1">
                {{ item + ' ' + $t(`terms.${item}`) }}</h3>
              <h4 class="text-secondary" v-else>{{ item[0]+'.'+item[1]+ ' ' + $t(`terms.${item}`) }}</h4>
              <p v-html="$t(`terms.${item}p`)"></p>
          </b-col>
        </b-row>
      </b-card>
    </b-col></b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'

export default {
  data () {
    return {
      structure: ['1', '2', '21', '22', '23', '24', '25', '26', '27', '28', '29', '3', '31', '32', '33']
    }
  },
  mounted () {
    core.index()
  }
}
</script>
